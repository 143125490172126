import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";
import axios from "axios";

function Questionario() {
  const [answers, setAnswers] = useState({
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
  });
  const [result, setResult] = useState(null);

  const handleChange = (question, answer) => {
    setAnswers({ ...answers, [question]: answer });
  };

 const handleSubmit = async (event) => {
   event.preventDefault();

   const answerCounts = { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0 };
   Object.values(answers).forEach((answer) => answerCounts[answer]++);

   const maxCount = Math.max(...Object.values(answerCounts));
   const mostFrequentAnswers = Object.keys(answerCounts).filter(
     (key) => answerCounts[key] === maxCount
   );

   let resultText = "";
   if (mostFrequentAnswers.length === 1) {
     // Un solo risultato
     switch (mostFrequentAnswers[0]) {
       case "A":
         resultText =
           'Maggioranza di risposte A: <a href="/ossidiana" style="font-weight: bold">Chiave Ossidiana</a> - Livello Base (Analisi Iniziale)<br />Sei alla ricerca di supporto per gestire le attività amministrative e contabili di base. <b>Chiave Ossidiana</b> ti aiuterà a delegare queste operazioni, permettendoti di focalizzarti sulla crescita della tua attività.';
         break;
       case "B":
         resultText =
           'Maggioranza di risposte B: <a href="/smeraldo" style="font-weight: bold">Chiave Smeraldo</a> - Ottimizzazione dei Processi<br />Hai bisogno di ottimizzare i processi interni per migliorare l\'efficienza operativa. <b>Chiave Smeraldo</b> offre soluzioni per semplificare le operazioni quotidiane e risparmiare tempo prezioso.';
         break;
       case "C":
         resultText =
           'Maggioranza di risposte C: <a href="/perla" style="font-weight: bold">Chiave Perla</a> - Chiarezza e Strategia<br />Stai cercando di definire una strategia chiara e stabilire obiettivi a lungo termine. <b>Chiave Perla</b> ti fornirà la consulenza strategica necessaria per ottenere chiarezza e guidare la tua azienda verso il successo.';
         break;
       case "D":
         resultText =
           'Maggioranza di risposte D: <a href="/ambra" style="font-weight: bold">Chiave Ambra</a> - Implementazione Innovativa<br />Desideri implementare nuove tecnologie e soluzioni innovative nel tuo business. <b>Chiave Ambra</b> ti aiuterà a introdurre strumenti avanzati per migliorare l\'efficienza e restare competitivo.';
         break;
       case "E":
         resultText =
           'Maggioranza di risposte E: <a href="/rubino" style="font-weight: bold">Chiave Rubino</a> - Espansione e Consolidamento<br />Sei pronto ad espandere il tuo business o consolidare la tua posizione nel mercato. <b>Chiave Rubino</b> offre supporto strategico per affrontare nuove sfide e opportunità di crescita.';
         break;
       case "F":
         resultText =
           'Maggioranza di risposte F: <a href="/cauda-pavonis" style="font-weight: bold">Cauda Pavonis</a> - Help Room (Consapevolezza e Revisione)<br />Senti il bisogno di una valutazione generale per capire dove intervenire. <b>Cauda Pavonis</b> – Help Room ti offre un\'analisi gratuita e una consulenza strategica per identificare le azioni necessarie a migliorare la tua attività.';
         break;
       default:
         resultText = "Errore nel calcolo del risultato.";
     }
   } else {
     // Più risultati a pari merito
     resultText =
       "<b>Hai ottenuto un punteggio equivalente in più aree:</b><br />";
     mostFrequentAnswers.forEach((answer) => {
       switch (answer) {
         case "A":
           resultText +=
             '<a href="/ossidiana" style="font-weight: bold">Chiave Ossidiana</a>:<br />Supporto per la gestione amministrativa e contabile.<br /> \n';
           break;
         case "B":
           resultText +=
             '<a href="/smeraldo" style="font-weight: bold">Chiave Smeraldo</a>:<br />Ottimizzazione dei processi interni.<br /> \n';
           break;
         case "C":
           resultText +=
             '<a href="/perla" style="font-weight: bold">Chiave Perla</a>:<br />Definizione di una strategia chiara.<br /> \n';
           break;
         case "D":
           resultText +=
             '<a href="/ambra" style="font-weight: bold">Chiave Ambra</a>:<br />Implementazione di tecnologie innovative.<br /> \n';
           break;
         case "E":
           resultText +=
             '<a href="/rubino" style="font-weight: bold">Chiave Rubino</a>:<br />Supporto per l\'espansione del business.<br /> \n';
           break;
         case "F":
           resultText +=
             '<a href="/cauda-pavonis" style="font-weight: bold">Cauda Pavonis</a>:<br />Valutazione generale e consulenza strategica.<br /> \n';
           break;
       }
     });
   }

   setResult(resultText);

   try {
     // Recupera l'indirizzo IP dell'utente
     const ipResponse = await fetch("https://api.ipify.org?format=json");
     const data = await ipResponse.json();
     const ipAddress = data.ip;

     // Invia i dati al backend, includendo l'indirizzo IP
     await axios.post("/api/questionari", {
       answers,
       mostFrequentAnswers,
       ipAddress,
     });

     console.log("Risposta salvata");
   } catch (error) {
     console.error("Errore durante il salvataggio:", error);
   }
 };
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>
                  Individua la Chiave giusta per il successo del tuo business
                </h2>
                <h5>
                  Rispondi a 5 domande e scopri qual è la soluzione ottimale per
                  te
                  <br />
                  <small><small><small>(devi fornire una risposta per ogni domanda)</small></small></small>
                </h5>
              </div>
              <div className="row mb_30">
                <form onSubmit={handleSubmit}>
                  <div className="col-12 mb_30">
                    <div
                      className="bar front expert"
                      data-skill="Qual è la tua principale sfida attuale come imprenditore
                        o lavoratore autonomo?"
                      style={{ fontSize: "1.1rem" }}
                    ></div>
                    <div>
                      <input
                        type="radio"
                        id="q1a"
                        name="q1"
                        value="A"
                        onChange={() => handleChange("q1", "A")}
                        required
                      />
                      <label htmlFor="q1a">
                        &nbsp; <b>A. </b>Gestire le attività amministrative e
                        contabili quotidiane.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q1b"
                        name="q1"
                        value="B"
                        onChange={() => handleChange("q1", "B")}
                        required
                      />
                      <label htmlFor="q1b">
                        &nbsp; <b>B. </b>Ottimizzare i processi interni per
                        migliorare l'efficienza operativa.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q1c"
                        name="q1"
                        value="C"
                        onChange={() => handleChange("q1", "C")}
                        required
                      />
                      <label htmlFor="q1c">
                        &nbsp; <b>C. </b>Definire una strategia chiara e
                        obiettivi aziendali a lungo termine.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q1d"
                        name="q1"
                        value="D"
                        onChange={() => handleChange("q1", "D")}
                        required
                      />
                      <label htmlFor="q1d">
                        &nbsp; <b>D. </b>Implementare nuove tecnologie e
                        soluzioni innovative nel business.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q1e"
                        name="q1"
                        value="E"
                        onChange={() => handleChange("q1", "E")}
                        required
                      />
                      <label htmlFor="q1e">
                        &nbsp; <b>E. </b>Espandere il business in nuovi mercati
                        o consolidare la posizione attuale.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q1f"
                        name="q1"
                        value="F"
                        onChange={() => handleChange("q1", "F")}
                        required
                      />
                      <label htmlFor="q1f">
                        &nbsp; <b>F. </b>Comprendere dove intervenire per
                        migliorare, ma non so da dove iniziare.
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mb_30">
                    <div
                      className="bar front expert mt-20"
                      data-skill="2. Come descriveresti la tua gestione del tempo nelle attività lavorative?"
                      style={{ fontSize: "1.1rem" }}
                    ></div>
                    <div>
                      <input
                        type="radio"
                        id="q2a"
                        name="q2"
                        value="A"
                        onChange={() => handleChange("q2", "A")}
                        required
                      />
                      <label htmlFor="q2a">
                        &nbsp; <b>A. </b>Dedico troppo tempo a compiti
                        amministrativi invece che alla crescita del business.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q2b"
                        name="q2"
                        value="B"
                        onChange={() => handleChange("q2", "B")}
                        required
                      />
                      <label htmlFor="q2b">
                        &nbsp; <b>B. </b>Sento che i miei processi sono
                        inefficaci e mi fanno perdere tempo.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q2c"
                        name="q2"
                        value="C"
                        onChange={() => handleChange("q2", "C")}
                        required
                      />
                      <label htmlFor="q2c">
                        &nbsp; <b>C. </b>Mi manca una direzione chiara, quindi
                        disperdo energie in attività non strategiche.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q2d"
                        name="q2"
                        value="D"
                        onChange={() => handleChange("q2", "D")}
                        required
                      />
                      <label htmlFor="q2d">
                        &nbsp; <b>D. </b>Vorrei adottare nuove tecnologie ma non
                        so quali scegliere o come implementarle.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q2e"
                        name="q2"
                        value="E"
                        onChange={() => handleChange("q2", "E")}
                        required
                      />
                      <label htmlFor="q2e">
                        &nbsp; <b>E. </b>Sto cercando di espandermi, ma incontro
                        ostacoli che rallentano il processo.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q2f"
                        name="q2"
                        value="F"
                        onChange={() => handleChange("q2", "F")}
                        required
                      />
                      <label htmlFor="q2f">
                        &nbsp; <b>F. </b>Mi sento bloccato e ho bisogno di una
                        valutazione esterna per capire come procedere.
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mb_30">
                    <div
                      className="bar front expert mt-20"
                      data-skill="3. Quale delle seguenti affermazioni rispecchia meglio la tua situazione attuale?"
                      style={{ fontSize: "1.1rem" }}
                    ></div>
                    <div>
                      <input
                        type="radio"
                        id="q3a"
                        name="q3"
                        value="A"
                        onChange={() => handleChange("q3", "A")}
                        required
                      />
                      <label htmlFor="q3a">
                        &nbsp; <b>A. </b>Sono sopraffatto dalla mole di lavoro
                        amministrativo e burocratico.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q3b"
                        name="q3"
                        value="B"
                        onChange={() => handleChange("q3", "B")}
                        required
                      />
                      <label htmlFor="q3b">
                        &nbsp; <b>B. </b>So che potrei essere più efficiente se
                        ottimizzassi i miei processi interni.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q3c"
                        name="q3"
                        value="C"
                        onChange={() => handleChange("q3", "C")}
                        required
                      />
                      <label htmlFor="q3c">
                        &nbsp; <b>C. </b>Non ho una strategia definita e questo
                        influisce sulla crescita del mio business.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q3d"
                        name="q3"
                        value="D"
                        onChange={() => handleChange("q3", "D")}
                        required
                      />
                      <label htmlFor="q3d">
                        &nbsp; <b>D. </b>La mia azienda necessita di innovazione
                        per restare competitiva nel mercato.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q3e"
                        name="q3"
                        value="E"
                        onChange={() => handleChange("q3", "E")}
                        required
                      />
                      <label htmlFor="q3e">
                        &nbsp; <b>E. </b>Voglio espandere la mia attività ma non
                        sono sicuro dei passi da compiere.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q3f"
                        name="q3"
                        value="F"
                        onChange={() => handleChange("q3", "F")}
                        required
                      />
                      <label htmlFor="q3f">
                        &nbsp; <b>F. </b>Sento che c'è qualcosa che non
                        funziona, ma non riesco a identificare il problema.
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mb_30">
                    <div
                      className="bar front expert mt-20"
                      data-skill="4. Qual è il tuo obiettivo principale per i prossimi 6 mesi?"
                      style={{ fontSize: "1.1rem" }}
                    ></div>
                    <div>
                      <input
                        type="radio"
                        id="q4a"
                        name="q4"
                        value="A"
                        onChange={() => handleChange("q4", "A")}
                        required
                      />
                      <label htmlFor="q4a">
                        &nbsp; <b>A. </b>Delegare le attività amministrative per
                        concentrarmi sul mio core business.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q4b"
                        name="q4"
                        value="B"
                        onChange={() => handleChange("q4", "B")}
                        required
                      />
                      <label htmlFor="q4b">
                        &nbsp; <b>B. </b>Migliorare l'efficienza e ridurre gli
                        sprechi di tempo nelle operazioni quotidiane.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q4c"
                        name="q4"
                        value="C"
                        onChange={() => handleChange("q4", "C")}
                        required
                      />
                      <label htmlFor="q4c">
                        &nbsp; <b>C. </b>Definire una strategia chiara e
                        stabilire obiettivi raggiungibili.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q4d"
                        name="q4"
                        value="D"
                        onChange={() => handleChange("q4", "D")}
                        required
                      />
                      <label htmlFor="q4d">
                        &nbsp; <b>D. </b>Integrare soluzioni tecnologiche
                        innovative per migliorare i processi.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q4e"
                        name="q4"
                        value="E"
                        onChange={() => handleChange("q4", "E")}
                        required
                      />
                      <label htmlFor="q4e">
                        &nbsp; <b>E. </b>Espandere il mio business e consolidare
                        la mia posizione nel mercato.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q4f"
                        name="q4"
                        value="F"
                        onChange={() => handleChange("q4", "F")}
                        required
                      />
                      <label htmlFor="q4f">
                        &nbsp; <b>F. </b>Ricevere una consulenza per
                        identificare le aree chiave di miglioramento.
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mb_30">
                    <div
                      className="bar front expert mt-20"
                      data-skill="5. Come valuti l'utilizzo di supporto esterno per migliorare il tuo business?"
                      style={{ fontSize: "1.1rem" }}
                    ></div>
                    <div>
                      <input
                        type="radio"
                        id="q5a"
                        name="q5"
                        value="A"
                        onChange={() => handleChange("q5", "A")}
                        required
                      />
                      <label htmlFor="q5a">
                        &nbsp; <b>A. </b>Necessario, soprattutto per gestire le
                        attività amministrative che mi distraggono.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q5b"
                        name="q5"
                        value="B"
                        onChange={() => handleChange("q5", "B")}
                        required
                      />
                      <label htmlFor="q5b">
                        &nbsp; <b>B. </b>Utile, per ottimizzare i processi e
                        aumentare l'efficienza.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q5c"
                        name="q5"
                        value="C"
                        onChange={() => handleChange("q5", "C")}
                        required
                      />
                      <label htmlFor="q5c">
                        &nbsp; <b>C. </b>Indispensabile, ho bisogno di supporto
                        per definire la mia strategia aziendale.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q5d"
                        name="q5"
                        value="D"
                        onChange={() => handleChange("q5", "D")}
                        required
                      />
                      <label htmlFor="q5d">
                        &nbsp; <b>D. </b>Fondamentale, per implementare
                        correttamente nuove tecnologie.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q5e"
                        name="q5"
                        value="E"
                        onChange={() => handleChange("q5", "E")}
                        required
                      />
                      <label htmlFor="q5e">
                        &nbsp; <b>E. </b>Strategico, per espandere il mio
                        business con successo.
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="q5f"
                        name="q5"
                        value="F"
                        onChange={() => handleChange("q5", "F")}
                        required
                      />
                      <label htmlFor="q5f">
                        &nbsp; <b>F. </b>Benefico, ho bisogno di una valutazione
                        esterna per capire come procedere.
                      </label>
                    </div>
                  </div>
                  <div className="col-12 text text-right mt-20">
                    <button type="submit" className="btn btn-dark">
                      Invia
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {result && (
              <div className="section-header">
                <h2>Risultato:</h2>
                <p dangerouslySetInnerHTML={{ __html: result }}></p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questionario;
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function Perla() {
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Chiave Perla</h2>
                <h5>Chiarezza e Strategia</h5>
                <img
                  src="/assets/images/chiavi/chiave_perla.png"
                  alt="Animus Operandi Chiave Perla"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Raggiungi chiarezza, definisci la tua strategia</b>
                  <br />
                  Una visione chiara e definita è cruciale per la crescita a
                  lungo termine della tua azienda. Senza una strategia concreta
                  e ben strutturata, rischi di navigare senza direzione.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto costa non avere chiarezza?</b>
                  <br />
                  Senza una strategia ben definita, puoi spendere tempo in
                  attività che non portano risultati. Un'azienda senza chiarezza
                  può perdere <b>20-25 ore al mese</b> in operazioni non
                  coordinate o in progetti che non producono valore. Se il tuo
                  tempo fatturabile vale 50,00 € all'ora, potresti "perdere"{" "}
                  <b>1.000-1.250 v€ al mese</b> in produttività non ottimale.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  Con <b>Chiave Perla</b> aiutiamo gli imprenditori a
                  raggiungere chiarezza e a definire strategie mirate. Risparmi
                  tempo prezioso, riduci il rischio di decisioni sbagliate e
                  costruisci una base solida per il successo a lungo termine.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  A soli <b>289,00* €</b> al mese.
                  <br />
                  Fino al 20 dicembre 2024 puoi usufruire di{" "}
                  <u>
                    1 mese gratis e i successivi 5 mesi a soli{" "}
                    <b style={{ color: "#034099" }}>199,00* €</b>/mese
                  </u>
                  , utilizzando il codice <b>PEARL24</b>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi di Chiave Perla</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Chiarezza strategica</b>:
                      Eliminiamo le inefficienze e impostiamo una visione chiara
                      per la tua azienda.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2.{" "}
                      <b>Ottimizzazione delle decisioni</b>: Prendi decisioni
                      basate su dati e strategie concrete.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3. <b>Focus sugli obiettivi</b>: Ti
                      aiutiamo a mantenere il focus sui tuoi obiettivi aziendali
                      principali.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Un'azienda che fatica a definire il proprio mercato target e
                  il posizionamento può risparmiare 20 ore al mese con il nostro
                  supporto strategico, trovando soluzioni mirate che portano a
                  una crescita sostenibile.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business, oppure
                  richiedi una call conoscitiva con un nostro responsabile.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a
                  href="https://buy.stripe.com/3cs17agpR28D3Xa4gn"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-dark btn-block mt-20"
                >
                  Ottieni subito la tua Chiave Perla
                </a>
                <p
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.8rem",
                    fontStyle: "oblique",
                  }}
                >
                  <b>*</b> tutti i prezzi indicati sono da intendersi IVA e
                  oneri inclusi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Perla;
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function Smeraldo() {
  
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Chiave Smeraldo</h2>
                <h5>Ottimizzazione dei Processi</h5>
                <img
                  src="/assets/images/chiavi/chiave_smeraldo.png"
                  alt="Animus Operandi Chiave Smeraldo"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Focalizzati sulla crescita, semplifica le operazioni</b>
                  <br />
                  Quando gestisci un'attività in crescita, il tempo diventa la
                  tua risorsa più preziosa. Con il passare del tempo, gestire
                  attività amministrative e operazioni quotidiane diventa sempre
                  più complesso e inefficiente, facendoti perdere opportunità di
                  crescita.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa non ottimizzare?</b>
                  <br />
                  Un imprenditore che non ottimizza i suoi processi può spendere
                  15-20 ore al mese in operazioni non produttive, come la
                  ricerca di documenti, la gestione di scadenze fiscali o la
                  comunicazione inefficace tra i reparti. Se il tuo tempo
                  fatturabile vale 40€ all'ora, stai spendendo inutilmente tra{" "}
                  <b>600€ e 800€ al mese</b>.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  Con <b>Chiave Smeraldo</b> ottimizzi i tuoi processi con un
                  supporto personalizzato e innovativo. Per un costo inferiore
                  rispetto a un’assunzione part-time o freelance, il nostro team
                  gestisce operazioni chiave, eliminando inefficienze. Così puoi
                  risparmiare almeno 10-15 ore al mese, focalizzandoti sulla
                  crescita del tuo business.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  A soli <b>167,00* €</b> al mese.
                  <br />
                  Fino al 20 dicembre 2024 puoi usufruire di{" "}
                  <u>
                    1 mese gratis e i successivi 5 mesi a soli{" "}
                    <b style={{ color: "#034099" }}>119,00* €</b>/mese
                  </u>
                  , utilizzando il codice <b>EMER24</b>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi di Chiave Smeraldo</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Aumento dell’efficienza</b>:
                      Riorganizziamo e semplifichiamo i tuoi processi aziendali,
                      aiutandoti a lavorare in modo più smart.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2. <b>Riduci il tempo sprecato</b>:
                      Automatizziamo e ottimizziamo compiti ripetitivi e lunghi.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3. <b>Focus sulle vendite</b>: Con
                      più tempo libero, puoi concentrarti su strategie di
                      crescita e vendite.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 4. <b>Strumenti innovativi</b>:
                      Introduciamo soluzioni che riducono i costi operativi,
                      portandoti a risultati più rapidi.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Un imprenditore che gestisce un piccolo team, senza un
                  processo chiaro per monitorare il lavoro, può risparmiare fino
                  a 10 ore a settimana ottimizzando la gestione interna e
                  automatizzando alcune attività ripetitive.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business, oppure
                  richiedi una call conoscitiva con un nostro responsabile.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a
                  href="https://buy.stripe.com/8wM3fi0qT6oTctG8wC"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-dark btn-block mt-20"
                >
                  Ottieni subito la tua Chiave Smeraldo
                </a>
                <p
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.8rem",
                    fontStyle: "oblique",
                  }}
                >
                  <b>*</b> tutti i prezzi indicati sono da intendersi IVA e
                  oneri inclusi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Smeraldo;
import React, { useEffect } from "react";
import "../index.css"; // Importa il file CSS globale

function HeaderHome() {
  useEffect(() => {
    const isHomepage = window.location.pathname === "/"; // Assumiamo che la homepage sia "/"
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 500;

      const header = document.querySelector(".supertop"); // Seleziona l'header

      if (isHomepage) {
        if (scrollPosition > threshold) {
          header.style.backgroundColor = "#034099";
        } else {
          header.style.backgroundColor = "transparent"; // Ripristina il colore originale (trasparente)
        }
      } else {
        // Nelle altre pagine, imposta sempre il colore di sfondo
        header.style.backgroundColor = "#034099 !important";
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Pulisci il listener di eventi quando il componente viene smontato
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="supertop">
      <section
        id="topbar"
        className="topbar d-flex align-items-center"
        style={{ position: "relative", top: "16px" }}
      >
        <div className="container d-flex justify-content-end">
          <div className="social-links d-none d-md-flex align-items-center">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:info@animusoperandi.it">
                  info@animusoperandi.it
                </a>
              </i>
              <i className="bi bi-phone d-flex align-items-center ms-4">
                <span>+39 351 377 2245</span>
              </i>
            </div>
            {/* <span className="seprator"> | </span>
            <a href="#!" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#!" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#!" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a> */}
          </div>
        </div>
      </section>
      <header id="header" className="header d-flex align-items-center">
        <div className="container container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <img
              src="/assets/images/logo-animus-operandi-chiaro.png"
              alt="Animus Operandi"
              className="img-flex"
            />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#servizi">Cos'è Animus Operandi</a>
              </li>
              <li>
                <a href="#chi-siamo">Chi siamo</a>
              </li>
              <li className="dropdown">
                <a href="#!" className="active">
                  <span>Le soluzioni</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul className="dropdown-active">
                  <li
                    style={{
                      paddingBottom: "1.5vh",
                      borderBottom: "1px solid silver",
                    }}
                  >
                    <a href="/questionario">La chiave giusta in 5 domande</a>
                  </li>
                  <li>
                    <a href="/ossidiana">Chiave Ossidiana</a>
                  </li>
                  <li>
                    <a href="/smeraldo">Chiave Smeraldo</a>
                  </li>
                  <li>
                    <a href="/perla">Chiave Perla</a>
                  </li>
                  <li>
                    <a href="/ambra">Chiave Ambra</a>
                  </li>
                  <li
                    style={{
                      paddingBottom: "1.5vh",
                      borderBottom: "1px solid silver",
                    }}
                  >
                    <a href="/rubino">Chiave Rubino</a>
                  </li>
                  <li>
                    <a href="/cauda-pavonis">Cauda Pavonis (help room)</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
              {/* <li><a href="blogs.html">Blogs</a></li> */}
              <li>
                <a href="#contatti" data-toggle="dropdown">
                  Contatti
                </a>
              </li>
              <li className="dropdown">
                <a href="#!" className="active">
                  <span>Utili</span>{" "}
                  <i className="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul className="dropdown-active">
                  <li
                    style={{
                      paddingBottom: "1.5vh",
                      borderBottom: "1px solid silver",
                    }}
                  >
                    <a href="https://app.animusoperandi.it/">Login</a>
                  </li>
                  {/* <li><a href="register.html">Signup</a></li>
                    <li><a href="blogs.html">Blogs</a></li>
                    <li><a href="blogs-details.html">Blog Details</a></li>
                    <li><a href="coming-soon.html">Coming Soon</a></li> */}
                  <li>
                    <a href="/termini-e-condizioni">Termini & Condizioni</a>
                  </li>
                  <li>
                    <a href="/policies">Informativa Privacy</a>
                  </li>
                  <li>
                    <a href="/cookie">Cookie Policy</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        </div>
      </header>
    </div>
  );
}

export default HeaderHome;

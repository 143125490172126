import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function Ambra() {
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Chiave Ambra</h2>
                <h5>Implementazione Innovativa</h5>
                <img
                  src="/assets/images/chiavi/chiave_ambra.png"
                  alt="Animus Operandi Chiave Ambra"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>
                    Implementa soluzioni innovative, raggiungi la maturità
                    aziendale
                  </b>
                  <br />
                  L'innovazione è la chiave per il successo a lungo termine. Ma
                  implementare nuove tecnologie e soluzioni senza una guida può
                  essere dispendioso in termini di tempo e denaro.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto costa non innovare?</b>
                  <br />
                  Un'azienda che non riesce a innovare o implementare nuove
                  soluzioni rischia di restare indietro rispetto alla
                  concorrenza. Potresti spendere <b>30 ore al mese</b> solo per
                  gestire processi manuali e non automatizzati, il che potrebbe
                  costarti <b>1.500 € al mese</b> in tempo e opportunità perse.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  Con <b>Chiave Ambra</b> introduciamo strumenti innovativi e
                  ottimizziamo l’implementazione delle soluzioni. Riduciamo il
                  tempo necessario per l’adozione di nuove tecnologie e ti
                  supportiamo nell'integrare soluzioni che fanno risparmiare
                  tempo e risorse.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  A soli <b>429,00* €</b> al mese.
                  <br />
                  Fino al 20 dicembre 2024 puoi usufruire di{" "}
                  <u>
                    1 mese gratis e i successivi 5 mesi a soli{" "}
                    <b style={{ color: "#034099" }}>299,00* €</b>/mese
                  </u>
                  , utilizzando il codice <b>EMBER24</b>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi di Chiave Ambra</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Innovazione continua</b>:
                      Introduciamo nuove tecnologie per rendere i tuoi processi
                      più efficienti.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2.{" "}
                      <b>Riduzione dei costi operativi</b>: L’innovazione riduce
                      i costi legati a processi obsoleti o inefficaci.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3.{" "}
                      <b>Miglioramento della produttività</b>: Con strumenti
                      innovativi, il tuo team lavora meglio e più velocemente.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Un'azienda che non ha ancora integrato strumenti digitali di
                  gestione finanziaria potrebbe risparmiare 10-15 ore al mese,
                  migliorando la gestione dei flussi di cassa e riducendo gli
                  errori contabili.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business, oppure richiedi una call conoscitiva con un nostro responsabile.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a
                  href="https://buy.stripe.com/bIYaHK8Xp9B579m7sA"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-dark btn-block mt-20"
                >
                  Ottieni subito la tua Chiave Ambra
                </a>
                <p
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.8rem",
                    fontStyle: "oblique",
                  }}
                >
                  <b>*</b> tutti i prezzi indicati sono da intendersi IVA e
                  oneri inclusi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ambra;
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function Rubino() {
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Chiave Rubino</h2>
                <h5>Espansione e Consolidamento</h5>
                <img
                  src="/assets/images/chiavi/chiave_rubino.png"
                  alt="Animus Operandi Chiave Rubino"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Consolida i tuoi successi, espandi i tuoi orizzonti</b>
                  <br />
                  Raggiunta la stabilità, il passo successivo è espandere il tuo
                  business in nuovi mercati o consolidare le operazioni
                  esistenti. Questo richiede una gestione solida e strategica.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto costa non consolidare o espandere?</b>
                  <br />
                  Non riuscire a consolidare il successo o a espandersi può far
                  perdere <b>40-50 ore al mese</b> in opportunità mancate.
                  Questo equivale a <b>perdere 2.000-2.500 € al mese</b> in
                  potenziali profitti non realizzati.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  Con <b>Chiave Rubino</b> ti supportiamo nel consolidamento
                  delle operazioni e nell'espansione della tua azienda. Risparmi
                  tempo e risorse, concentrandoti su strategie che ti permettono
                  di crescere senza sacrificare la qualità operativa.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  A soli <b>577,00* €</b> al mese.
                  <br />
                  Fino al 20 dicembre 2024 puoi usufruire di{" "}
                  <u>
                    1 mese gratis e i successivi 5 mesi a soli{" "}
                    <b style={{ color: "#034099" }}>399,00* €</b>/mese
                  </u>
                  , utilizzando il codice <b>RUBY24</b>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi di Chiave Rubino</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Espansione strategica</b>: Ti
                      aiutiamo a esplorare nuovi mercati senza compromettere
                      l’efficienza.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2.{" "}
                      <b>Consolidamento delle operazioni</b>: Rafforziamo le tue
                      strutture operative per garantirti una base solida da cui
                      crescere.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3. <b>Riduzione dei rischi</b>: Con
                      una strategia di espansione mirata, riduci i rischi di
                      insuccesso.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Un imprenditore che intende espandere la sua attività in nuovi
                  mercati internazionali potrebbe risparmiare 30 ore al mese in
                  gestione amministrativa e logistica, grazie al nostro supporto
                  strategico e operativo.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business, oppure
                  richiedi una call conoscitiva con un nostro responsabile.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a
                  href="https://buy.stripe.com/8wM5nq3D5aF9dxKaEN"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-dark btn-block mt-20"
                >
                  Ottieni subito la tua Chiave Rubino
                </a>
                <p
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.8rem",
                    fontStyle: "oblique",
                  }}
                >
                  <b>*</b> tutti i prezzi indicati sono da intendersi IVA e
                  oneri inclusi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Rubino;
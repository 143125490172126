import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function CaudaPavonis() {
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Cauda Pavonis</h2>
                <h5>
                  Help Room (Check-up Annuale e Supporto in Caso di Crisi)
                </h5>
                <img
                  src="/assets/images/chiavi/cauda_pavonis.png"
                  alt="Animus Operandi Cauda Pavonis"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>
                    Siamo qui per crescere insieme, senza lasciare nessuno
                    indietro.
                  </b>
                  <br />
                  La <b>Cauda Pavonis</b> è una fase dedicata a chi si trova in
                  difficoltà, a chi ha bisogno di riallinearsi, riposizionarsi o
                  semplicemente di un consiglio esterno. Ogni cliente ha diritto
                  a un{" "}
                  <b>
                    colloquio e consulenza personalizzata di 90 minuti,
                    completamente gratuiti
                  </b>{" "}
                  per un check-up annuale o in caso di emergenza.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto costa rimanere bloccato?</b>
                  <br />
                  Trovarti in una fase di stallo può costarti caro: decisioni
                  sbagliate, opportunità perse e insoddisfazione. Non cogliere i
                  segnali di difficoltà può portare a situazioni di crisi che
                  diventano sempre più difficili da risolvere. Il tempo
                  impiegato a capire come correggere la rotta da solo può essere
                  gravoso e inefficace.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  <b>Nulla!</b> La nostra Help Room è completamente gratuita,
                  perché crediamo che ogni imprenditore meriti supporto, anche
                  quando non si trova in una fase di crescita lineare. Il
                  servizio non è automatico né attivato sistematicamente, ma è
                  disponibile per chi ne ha bisogno. Lo scopo è quello di
                  aiutarti a rialzarti e a trovare le giuste soluzioni.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi della Cauda Pavonis – Help Room</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Riposizionamento strategico</b>
                      : Durante il colloquio, analizziamo le tue difficoltà e ti
                      aiutiamo a riposizionarti per tornare sulla giusta strada.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2. <b>Correzione di errori</b>:
                      Identifichiamo insieme le aree critiche che necessitano di
                      aggiustamenti e forniamo consigli su come migliorare la
                      tua situazione aziendale.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3.{" "}
                      <b>Supporto morale e strategico</b>: A volte, chiedere
                      aiuto è il primo passo per rimettersi in gioco. Ti
                      forniamo il supporto necessario per risolvere i tuoi
                      problemi senza impegno.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Immagina di aver attraversato un periodo difficile nella tua
                  attività. Ti senti bloccato, hai fatto degli errori e non sai
                  come ripartire. Con <b>Cauda Pavonis</b>, ricevi un'analisi
                  gratuita e 90 minuti di consulenza personalizzata per valutare
                  la tua situazione e trovare soluzioni pratiche per riprendere
                  il controllo.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Conclusioni</b>
                  <br />
                  Il nostro obiettivo è aiutare chi cresce, ma senza lasciare
                  nessuno indietro. Anche noi siamo caduti, e sappiamo quanto
                  sia importante avere la giusta volontà per rimettersi in
                  gioco. Non c’è vergogna nel chiedere aiuto: con{" "}
                  <b>Cauda Pavonis</b>, ti offriamo il supporto necessario per
                  tornare a crescere.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a href="/#contatti" className="btn btn-dark btn-block mt-20">
                  Richiedi la tua Cauda Pavonis ora!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CaudaPavonis;
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Header from "../components/Header";

function Ossidiana() {
  
  return (
    <div>
      <Header />
      <section id="domande" className="the-main-service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Chiave Ossidiana</h2>
                <h5>La Gestione Amministrativa in outsourcing</h5>
                <img
                  src="/assets/images/chiavi/chiave_ossidiana.png"
                  alt="Animus Operandi Chiave Ossidiana"
                  className="img-fluid mt-20"
                />
              </div>
              <div className="text text-left">
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Focalizzati sulla tua crescita, delega il resto</b>
                  <br />
                  Diventare lavoratore autonomo significa indossare mille
                  cappelli: vendita, produzione, contabilità, e gestione
                  amministrativa. Se gestisci tutto da solo, rischi di perdere
                  tempo e risorse preziose che potresti dedicare alla tua
                  attività principale.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto costa fare tutto da solo?</b>
                  <br />
                  Un neo lavoratore autonomo spende in media{" "}
                  <b>2-3 ore alla settimana</b> solo per la gestione
                  amministrativa, che si traducono in circa{" "}
                  <b>10-12 ore al mese</b>. Se il tuo tempo fatturabile vale{" "}
                  <b>40 euro all'ora</b>, stai "perdendo" <b>480 € al mese</b>.
                  Inoltre, formarti autonomamente in contabilità o assumere un
                  collaboratore può richiedere tempo e risorse ancora maggiori.
                  Assumere un collaboratore part-time, anche solo per poche ore
                  settimanali, può costare <b>da 600€ a 1.200€ al mese</b>,
                  senza contare i costi di formazione e gestione.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Quanto ti costa usare il nostro servizio?</b>
                  <br />
                  Con <b>Chiave Ossidiana</b> hai un team di esperti pronti a
                  gestire tutto per te. Non solo elimini i costi di
                  un’assunzione part-time o di un collaboratore, ma{" "}
                  <b>risparmi fino a 10-12 ore di lavoro al mese</b>, tempo che
                  puoi dedicare interamente alla tua attività produttiva.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  A soli <b>99,00* €</b> al mese.
                  <br />
                  Fino al 20 dicembre 2024 puoi usufruire di{" "}
                  <u>
                    1 mese gratis e i successivi 5 mesi a soli{" "}
                    <b style={{ color: "#034099" }}>69,00* €</b>/mese
                  </u>
                  , utilizzando il codice <b>OBSY24</b>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>I vantaggi di Chiave Ossidiana</b>
                  <ol>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 1. <b>Risparmi tempo prezioso</b>:
                      Non devi formare nessuno. I nostri professionisti
                      conoscono già il contesto amministrativo e fiscale, pronti
                      a entrare in azione.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 2. <b>Riduci i costi</b>: Eviti
                      l'assunzione di personale part-time o freelance. Con il
                      nostro piano base, gestiamo tutto per te a un costo fisso
                      mensile.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 3.{" "}
                      <b>Focalizzazione sulla crescita</b>: Delegando la parte
                      amministrativa, puoi concentrarti sulla produzione, sulle
                      vendite e sull'espansione della tua attività, migliorando
                      i tuoi profitti.
                    </li>
                    <li style={{ lineHeight: "1.8rem" }}>
                      &nbsp; &nbsp; &nbsp; 4. <b>Controllo costante</b>:
                      Mantieni sempre il controllo del flusso di cassa e dei
                      documenti amministrativi, evitando sanzioni o problemi
                      legati alla gestione non corretta.
                    </li>
                  </ol>
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Esempio pratico</b>
                  <br />
                  Immagina di essere un nuovo lavoratore autonomo che emette
                  poche fatture al mese. Se decidi di gestire tutto da solo,
                  perdi tempo prezioso che potrebbe essere destinato a far
                  crescere il tuo business. Assumere un collaboratore part-time
                  ti costerebbe molto di più di quanto investiresti in un
                  servizio come <b>Chiave Ossidiana</b>.<br />
                  <b>Con noi risparmi denaro, tempo e stress</b>, e puoi
                  concentrarti sul successo della tua impresa.
                </p>
                <p style={{ lineHeight: "1.8rem" }}>
                  <b>Conclusione</b>
                  <br />
                  Non aspettare di essere sopraffatto dalle incombenze
                  amministrative. Inizia con <b>Chiave Ossidiana</b> e ottieni
                  il supporto di un team esperto che ti permetterà di
                  concentrarti su ciò che conta davvero: la crescita del tuo
                  business.
                  <br /> <br />
                  Compila il{" "}
                  <a
                    href="/questionario"
                    style={{ fontWeight: "600", textDecoration: "underline" }}
                  >
                    test valutativo
                  </a>{" "}
                  per scoprire la Chiave giusta per il tuo business, oppure
                  richiedi una call conoscitiva con un nostro responsabile.
                </p>
              </div>
              <div className="the-main-service text-center mt-20">
                <a
                  href="https://buy.stripe.com/cN2bLOflN6oT8dq149"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-dark btn-block mt-20"
                >
                  Ottieni subito la tua Chiave Ossidiana
                </a>
                <p
                  style={{
                    fontSize: "0.8rem",
                    lineHeight: "1.8rem",
                    fontStyle: "oblique",
                  }}
                >
                  <b>*</b> tutti i prezzi indicati sono da intendersi IVA e
                  oneri inclusi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ossidiana;
import React from "react";
import "../index.css";
import Header from "../components/Header";
function Termini() {

  return (
    <div>
      <Header />
      <section id="termini" className="the-main-service text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>Termini e Condizioni</h2>
              </div>
              <div className="text text-left">
                <h4 className="text-center">Premesso</h4>
                <p>
                  – Che il servizio oggetto del presente contratto consiste
                  nella messa a disposizione della piattaforma gestionale online
                  denominata "<b>Animus Operandi</b>" che consente la raccolta,
                  gestione, organizzazione ed elaborazione di dati contabili e
                  finanziari di attività economiche;
                </p>
                <p>
                  – Che il Cliente dichiara esplicitamente ai sensi degli artt.
                  1341 e 1342 c.c„ di aver visionato, letto, compreso ed
                  accettato integralmente tutte le condizioni generali riportate
                  nel contratto allegato al presente modulo d'ordine;
                </p>
                <p>
                  – I corrispettivi indicati nel precedente modulo d'ordine sono
                  soggetti alle norme riportate nelle condizioni generali del
                  contratto di licenza d'uso della piattaforma gestionale
                  on-line denominata "<b>Animus Operandi</b>"; <br />– Eventuali
                  servizi e/o prodotti non previsti nel presente modulo d'ordine
                  e richiesti dal Cliente (quali, a titolo meramente
                  esemplificativo: formazione personalizzata, consulenza,
                  interventi on site o da remoto, assistenza telefonica e/o
                  telematica supplementare, ecc.) potranno essere forniti da
                  Catia Lettieri mediante separata pattuizione; <br />– La
                  modalità di pagamento pattuita è mediante Carta di Credito
                  tramite il servizio di getaway di pagamento Stripe.
                </p>
                <p>Tutto ciò premesso, il Cliente</p>
                <h4 className="text-center">Ordina</h4>
                <p>
                  L'Utilizzo della piattaforma gestionale on-line "
                  <b>Animus Operandi</b>" con la formula di pagamento prescelta
                  sul sito{" "}
                  <a href="https://animusoperandi.it/">animusoperandi.it</a> al
                  prezzo corrispondente indicato.{" "}
                </p>
                Istruzioni per l'invio del presente ordine<p></p>
                <ol>
                  <li>
                    1. L'ordine viene inteso inviato e completato al momento
                    dell'effettuazione dell'intera procedura – incluso il
                    pagamento sulla piattaforma sicura Stripe (
                    <a
                      href="https://www.stripe.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.stripe.com
                    </a>
                    ) e la ricezione da parte della stessa dell'avvenuto
                    accredito.
                  </li>
                  <li>
                    2. Allegati al presente modulo d'ordine da far pervenire una
                    volta effettuato il pagamento a{" "}
                    <a href="mailto:amministrazione@animusoperandi.it">
                      amministrazione@animusoperandi.it
                    </a>
                    : <br />
                    • Visura Camerale <br />
                    • Carta d'identità del Legale Rappresentante
                    <br />• Dati completi per l'espletamento delle pratiche
                    amministrative (Ragione Sociale completa, Indirizzo PEC /
                    Codice Univoco per l'emissione di fatture elettroniche)
                  </li>
                </ol>
                <h4 className="text-center" style={{ marginTop: "3rem" }}>
                  CONDIZIONI GENERALI CONTRATTO DI LICENZA D'USO DELLA
                  <br />
                  PIATTAFORMA GESTIONALE ONLINE DENOMINATA "ANIMUS OPERANDI"
                </h4>
                <p>
                  La Concedente, operante nel settore consulenziale ed
                  amministrativo è proprietaria della piattaforma gestionale
                  online denominata "Animus Operandi". La piattaforma Animus
                  Operandi è protetta dalle leggi sul diritto d'autore
                  (copyright) e sui marchi. Il Cliente ha manifestato il proprio
                  interesse a ricevere in licenza d'uso l'accesso al programma
                  sopracitato, in quanto adatto alle proprie esigenze
                  professionali/imprenditoriali. Le parti, con il presente
                  contratto, intendono regolare i termini e le condizioni di
                  utilizzo della piattaforma. Tanto premesso, che costituisce
                  parte integrale e sostanziale del presente contratto, le parti
                  convengono e stipulano quanto segue:
                </p>
                <p>
                  <b>1. Definizioni</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  1.1 Si intende per:
                  <br />
                  a) Piattaforma gestionale on-line denominata "Animus Operandi"
                  (di seguito denominata anche solo "software"): il programma
                  informatico in cloud che consente la raccolta, gestione,
                  organizzazione ed elaborazione di dati economici, fiscali e
                  amministrativi relativi ad attività economiche con Partita IVA
                  attiva;
                  <br />
                  b) Attivazione: attivazione del servizio attraverso la
                  compilazione del modulo d'ordine online, fornito privatamente
                  previa colloquio con il richiedente;
                  <br />
                  c) Canone: Il canone è quello pubblicato all'indirizzo web
                  www.animusoperandi.it; da corrispondere anticipatamente al
                  momento dell'attivazione del servizio mediante la
                  compilazione, da parte del Cliente, del modulo d'ordine
                  disponibile nell'area riservata del Cliente stesso (attivata
                  dopo il citato colloquio con un ns. responsabile) ed è dovuto
                  esclusivamente per il programma gestionale denominato "Animus
                  Operandi";
                  <br />
                  d) Concedente o Produttore: Catia Lettieri con sede legale in
                  Via G. Mazzini, 38 – 05021 Acquasparta (TR) P.IVA 01683150559,
                  soggetto erogatore del servizio disciplinato dalle presenti
                  condizioni generali di servizio;
                  <br />
                  e) Cliente o Utilizzatore: colui che utilizza il servizio;
                </p>
                <p>
                  <b>2. Oggetto</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  2.1 II produttore concede in licenza d'uso all'utilizzatore la
                  piattaforma gestionale on-line denominata "Animus Operandi".
                  <br />
                  La licenza d'uso viene concessa a titolo non esclusivo e non è
                  trasferibile a terzi.
                  <br />
                  La proprietà del software non è trasferita all'utilizzatore.
                  <br />
                  Il Cliente resta l'unico responsabile della correttezza delle
                  informazioni fornite al concedente e della scelta del
                  programma.
                  <br /> <br />
                  2.2 La detta piattaforma gestionale on-line può essere
                  utilizzata su più stazioni di lavoro senza le apposite licenze
                  clienti.
                  <br /> <br />
                  2.3 II servizio di fruizione della piattaforma gestionale
                  on-line è erogato dalla concedente con caratteristiche e
                  prezzi corrispettivi descritti sul sito Animus Operandi
                  all'URL www.animusoperandi.it e mette a apposizione
                  dell'utilizzatore i seguenti diritti;
                  <br />
                  a) l'utilizzatore può installare, utilizzare, accedere,
                  visualizzare ed eseguire la piattaforma gestionale on-line
                  "Animus Operandi" su qualunque computer, workstation,
                  terminale o altro dispositivo elettronico digitale per il
                  quale è stato progettato. Una stessa licenza può essere
                  condivisa o usata in concomitanza in computer diversi, purché
                  facenti riferimento alla medesima Ragione Sociale
                  dell'Ordinante;
                  <br />
                  b) assistenza tramite il servizio chat presente nell'Area
                  Riservata dell'utente negli orari di disponibilità del
                  personale tecnico;
                  <br />
                  c) L'attivazione del servizio
                  <br />
                  d) Il produttore si riserva tutti i diritti non espressamente
                  concessi dal presente contratto.
                </p>
                <p>
                  <b>3. Conclusione ed efficacia del contratto</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  3.1 II rapporto contrattuale si intende concluso con il
                  pagamento anticipato del canone di cui all'alt. 1 co. 1 lett.
                  c) e precisato, nel modello d'ordine, al momento
                  dell'attivazione del servizio e con l'accettazione dello
                  stesso da parte del produttore, senza alcun obbligo di
                  comunicazione ma dando seguito alle procedure di attivazione
                  del servizio durante il colloquio precedentemente visto.
                  <br /> <br />
                  3.2 L'attivazione del servizio avverrà entro un massimo di 48
                  ore lavorative.
                  <br /> <br />
                  3.3 Non sarà possibile richiedere il rimborso dell'importo del
                  canone.
                </p>
                <p>
                  <b>4. Durata</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  4.1 II rapporto contrattuale ha durata di un anno, a seconda
                  dell'opzione prescelta dal Cliente nel modulo d'ordine, che
                  decorre dal giorno della attivazione del servizio con la
                  consegna al Cliente dei codici di accesso (Login e Password)
                  per consentire di accedere alla piattaforma e con il pagamento
                  da parte del Cliente del canone come specificato all art. 1
                  co. 1 lett. c) per la fruizione del software "Animus
                  Operandi".
                  <br /> <br />
                  4.2 II contratto si intende a tempo indeterminato.
                  <br />
                  Ciascuna delle parti può recedere dal contratto a mezzo
                  comunicazione scritta raccomandata con ricevuta di ritorno con
                  un preavviso di almeno 30 giorni.
                </p>
                <p>
                  <b>5. Modifiche delle condizioni generali del Servizio</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  5.1 La piattaforma del gestionale "Animus Operandi" potrà
                  essere utilizzato corrispondendo il canone mensile stabilito
                  (cfr. sezione "Prezzi" del sito www.animusoperandi.it), da
                  versare al concedente entro la scadenza di ogni periodo per il
                  quale si è sottoscritto l'abbonamento.
                  <br />
                  Il pagamento avverrà a mezzo Carta di Credito, ovvero Addebito
                  SEPA, tramite il servizio di getaway fornito da Stripe
                  (https://www.stripe.com).
                  <br />
                  É possibile prevedere il pagamento anticipato annuale (per 12
                  mesi consecutivi al prezzo di 10) del servizio (cfr. sezione
                  "Prezzi" del sito www.animusoperandi.it) e il pagamento
                  dovrà avvenire in maniera anticipata al momento della
                  sottoscrizione del presente contratto con le medesime modalità
                  della sottoscrizione mensile.
                  <br />
                  Al termine del periodo per il quale si è sottoscritto
                  l'abbonamento, il contratto resterà in essere e il Cliente
                  sarà tenuto al pagamento del canone previsto per il piano
                  sottoscritto, sempre mediante Carta di Credito o Addebito SEPA
                  tramite il getaway di pagamento Stripe.
                  <br />
                  In difetto il contratto dovrà intendersi risolto per
                  inadempimento del Cliente e il produttore sospenderà
                  l'erogazione di qualsiasi servizio fornito.
                  <br />
                  Resta salva la facoltà per il Cliente di operare un nuovo
                  pagamento per un periodo a scelta tra quelli offerti.
                  <br /> <br />
                  5.2 II canone sarà dovuto dal mese di attivazione del servizio
                  come indicato dall'alt. 4 co. 1.
                  <br /> <br />
                  5.3 II canone del servizio sarà dovuto dal Cliente in via
                  anticipata dal momento dell'attivazione del servizio, mediante
                  la compilazione del modulo d'ordine disponibile all'indirizzo
                  web fornito privatamente durante il già citato colloquio.
                  <br /> <br />
                  5.4 Fermo quanto stabilito dai precedenti commi, per ritardi
                  di pagamento nei 5 giorni successivi alla data di scadenza, la
                  concedente provvedere all'immediata sospensione del servizio.
                  Decorso detto termine, il contratto si intenderà risolto, non
                  sarà possibile accedere al database e l'archivio verrà
                  completamente cancellato entro il termine di 60 giorni
                  successivi allo spirare del predetto termine.
                </p>
                <p>
                  <b>6. Modifiche delle condizioni generali del Servizio</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  6.1 La concedente si riserva la facoltà di aggiornare in
                  qualunque momento le specifiche tecniche del servizio per
                  migliorarne le prestazioni e le funzionalità. Ove la modifica
                  comportasse nuove modalità di utilizzo dello stesso, queste
                  verranno comunicate sul sito www.animusoperandi.it.
                  <br /> <br />
                  6.2 La concedente si riserva inoltre la facoltà di variare i
                  prezzi del servizio e dei servizi accessori in qualsiasi
                  momento per sopravvenuti aumenti dei costi di gestione
                  pubblicando i nuovi listini sul sito www.animusoperandi.it o
                  comunicandoli direttamente all'utente. Con il pagamento del
                  nuovo prezzo corrispettivo il Cliente manifesterà la propria
                  accettazione delle variazioni per comportamento concludente.
                  <br /> <br />
                  6.3 Qualora il Cliente non intendesse accettare la modifica
                  dei prezzi di cui al precedente comma 6.2, questi potrà
                  recedere dal servizio dandone comunicazione alla concedente
                  mediante lettera raccomandata A/R entro 30 giorni dalla
                  comunicazione della modifica, fermo restando il diritto della
                  concedente a percepire il canone relativo al servizio fino a
                  quel momento prestato, con esclusione di ogni altra indennità.
                  <br />
                  In difetto di qualsiasi comunicazione nel termine anzidetto,
                  la modifica si riterrà accettata.
                </p>
                <p>
                  <b>7. Obblighi e responsabilità del Cliente</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  7.1 L'attivazione della piattaforma gestionale online da parte
                  del Cliente non gli attribuisce alcun diritto sulle tecniche e
                  procedure contenuti in esso che restano di proprietà della
                  concedente e non possono essere utilizzate per scopi diversi
                  da quelli indicati all'interno del sito ufficiale del
                  servizio.
                  <br /> <br />
                  7.2 II Cliente si obbliga a non concedere, né a titolo
                  gratuito né a titolo oneroso, il servizio in uso o a darlo in
                  sublicenza a terzi – non intendendosi per tali i dipendenti e
                  collaboratori dello stesso -, ivi comprese società
                  controllanti, controllate o appartenenti al medesimo gruppo
                  del Cliente.
                  <br /> <br />
                  7.3 II Cliente si obbliga a non trasferire a terzi (come
                  definiti nel precedente comma), neppure parzialmente, i codici
                  alfanumerici (denominati username e password) necessari per la
                  fruizione del servizio, assumendone la custodia ed
                  obbligandosi, in particolare, a conservarli con massima cura e
                  riservatezza ed a risponderne nei confronti della concedente e
                  di eventuali terzi in caso di violazione, anche parziale,
                  degli obblighi posti a suo carico dal presente comma.
                </p>
                <p>
                  <b>8. Obblighi della Concedente e garanzie</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  8.1 La concedente si impegna ad assicurare la continuità ed il
                  livello qualitativo del servizio come descritto nel sito
                  www.animusoperandi.it salvo quanto previsto dai successivi
                  artt. 9,10,11 e 12.
                  <br /> <br />
                  8.2 La concedente garantisce che la piattaforma gestionale
                  online concessa in licenza è conforme alle caratteristiche
                  tecniche e funzionali descritte nel sito
                  www.animusoperandi.it.
                  <br />
                  La presente garanzia, condizionata al buon funzionamento
                  dell'hardware del licenziatario ed al suo corretto uso, si
                  riferisce esclusivamente ai difetti di funzionamento insiti
                  nell'applicativo. Non si estende pertanto a malfunzionamenti
                  dovuti all'errata utilizzazione e/o all'impiego atipico della
                  piattaforma.
                  <br /> <br />
                  8.3 La concedente, nell'impegnarsi a mantenere la piattaforma
                  gestionale online oggetto del presente contratto di licenza in
                  buono stato di manutenzione, garantisce che il servizio è in
                  grado di eseguire le operazioni e produrre i risultati
                  illustrati al Cliente il quale, con l'adesione al servizio,
                  dichiara di esserne stato messo al corrente e che soddisfano
                  le sue esigenze.
                  <br /> <br />
                  8.4 La concedente si impegna al mantenimento della piattaforma
                  gestionale online oggetto del presente contratto di licenza in
                  condizioni tali da garantirne le funzionalità necessarie
                  all'uso convenuto.
                </p>
                <p>
                  <b>9. Sospensioni del Servizio</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  9.1 La concedente non garantisce all'utilizzatore la
                  continuità del collegamento alla piattaforma gestionale online
                  e, pertanto, non sarà in alcun modo responsabile per
                  qualsivoglia disservizio tecnico che impedisca il collegamento
                  alla piattaforma, o a singole pagine di esso e/o
                  l'utilizzazione di una o più funzionalità del medesimo.
                  <br /> <br />
                  9.2 Catia Lettieri declina ogni responsabilità per
                  interruzioni del servizio per cause imprevedibili, eccezionali
                  e di forza maggiore quali attacchi hacker, guasti alle linee
                  telefoniche, elettriche e/o alle reti nazionali o
                  internazionali e/o agli apparati tecnici propri o di altri
                  operatori necessari per il regolare funzionamento del servizio
                  e non dipendenti da cattiva manutenzione imputabile alla
                  concedente.
                  <br /> <br />
                  9.3 La concedente, qualora sospenda il servizio per il
                  verificarsi di una delle ipotesi di cui al precedente comma,
                  non sarà tenuta al rimborso della quota del prezzo
                  corrispettivo del servizio corrispondente al periodo non
                  goduto.
                  <br /> <br />
                  9.4 La concedente non sarà, in alcuna ipotesi, tenuta al
                  pagamento di ulteriori indennità, neppure di carattere
                  risarcitorio, relative a danni diretti od indiretti comunque
                  subiti dal Cliente o da terzi in dipendenza dell'uso o dal
                  mancato uso del servizio e dei servizi accessori.
                  <br /> <br />
                  9.5 A tale scopo l'utilizzatore prende atto e dichiara che non
                  avrà nulla a che pretendere nei confronti di Catia Lettieri in
                  caso di disservizi che dovessero compromettere l'accesso alla
                  piattaforma o la sua corretta e completa funzionalità.
                </p>
                <p>
                  <b>10. Restituzione dei dati</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  10.1 II Cliente potrà effettuare un salvataggio dei propri
                  dati elaborati in qualsiasi momento per tutta la durata del
                  presente Contratto. Decorso tale termine i dati saranno
                  ulteriormente recuperabili entro 60 giorni.
                  <br />
                  Il Cliente prende atto che dopo 60 giorni dalla cessazione del
                  Contratto non sarà più possibile recuperare dati e/o
                  informazioni e/o contenuti da egli immessi nella piattaforma
                  "Animus Operandi" e si impegna, ora per allora, a procurarsi
                  tempestivamente prima della definitiva cessazione del presente
                  contratto una copia di tali dati e/o informazioni e/o
                  contenuti con le modalità previste dalla piattaforma "Animus
                  Operandi" (es. esportazione Excel o PDF). Per qualsiasi caso
                  di cessazione del contratto, il Cliente solleva, ora per
                  allora, Catia Lettieri da ogni e qualsiasi responsabilità per
                  l'eventuale perdita o danneggiamento totale o parziale di dati
                  e/o informazioni e/o contenuti immessi dal Cliente stesso.
                </p>
                <p>
                  <b>11. Esclusione di responsabilità per danni indiretti</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  11.1 In nessun caso Catia Lettieri sarà responsabile per i
                  danni (inclusi, senza limitazioni, il danno per perdita o
                  mancato guadagno, interruzione dell'attività, perdita delle
                  informazioni archiviate o altre perdite economiche) derivanti
                  dall'uso della piattaforma gestionale online o da attacchi
                  hacker, anche nel caso in cui Catia Lettieri sia stata
                  avvertita della possibilità di tali danni, fatto salvo quanto
                  inderogabilmente previsto dalla legge. In ogni caso, la
                  responsabilità di Catia Lettieri ai sensi del presente
                  contratto, sarà limitata ad un importo corrispondente a quello
                  effettivamente pagato dal Cliente per il software, che le
                  parti predeterminano a titolo di clausola penale.
                </p>
                <p>
                  <b>12. Forza maggiore</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  12.1 Nessuna delle parti sarà responsabile del mancato
                  adempimento di una qualsiasi delle obbligazioni previste nel
                  rapporto contrattuale qualora la causa sia un evento al di
                  fuori del suo controllo, non prevedibile e non evitabile. Ai
                  fini della presente clausola e senza che l'elencazione che
                  segue possa considerarsi esaustiva, un evento di forza
                  maggiore includerà: guerre dichiarate e non, cataclismi
                  naturali, esplosioni, incendi e distruzioni boicottaggi,
                  scioperi e serrate di ogni genere atti dell'Autorità legittimi
                  o meno, epidemie.
                  <br /> <br />
                  12.2 Qualora si verifichi un evento di forza maggiore, la
                  parte che ne subisce le conseguenze rendendosi inadempiente,
                  informerà immediatamente per iscritto l'altra parte del
                  verificarsi di tale evento.
                  <br /> <br />
                  12.3 Per l'intero periodo in cui l'evento di forza maggiore o
                  i suoi effetti permarranno, la parte inadempiente non sarà
                  considerata responsabile per la sua incapacità di eseguire le
                  obbligazioni la cui esecuzione è impedita dall'evento di forza
                  maggiore, fermo restando che dette obbligazioni verranno
                  adempiute non appena possibile e dopo il venir meno
                  dell'evento di forza maggiore.
                </p>
                <p>
                  <b>13. Clausola di riservatezza</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  13.1 II Cliente si obbliga a mantenere riservati gli aspetti
                  tecnici e le soluzioni adottate nella piattaforma gestionale
                  online fornita anche per particolari personalizzazioni
                  adottate nei suoi confronti.
                  <br /> <br />
                  13.2 La concedente si obbliga ad attuare tutte le misure
                  necessarie a garantire la sicurezza dei dati previste dal GDPR
                  679/16 e del d. Igs. n° 101/18 nella fornitura del servizio,
                  come disciplinato nel successivo art. 14.
                </p>
                <p>
                  <b>14. Privacy</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  14.1 I dati personali forniti dal Cliente a Catia Lettieri e
                  relativi al Cliente stesso sono tutelati ai sensi degli artt.
                  13 e 14 del Regolamento Europeo 679/2016 relativo alla
                  protezione dei dati personali ("GDPR"). Il titolare del
                  trattamento dei dati del Cliente è Catia Lettieri.
                  <br /> <br />
                  14.2 II conferimento di tutti i dati inerenti al presente
                  contratto è strettamente collegato all'esecuzione del rapporto
                  per cui l'eventuale mancato consenso porterà all'impossibilità
                  di esecuzione del rapporto contrattuale.
                  <br /> <br />
                  14.3 Catia Lettieri non vende, né affitta, né concede in
                  prestito a terze parti, i propri elenchi di utenti e i
                  relativi dati.
                  <br /> <br />
                  {/* 14.4 Catia Lettieri si occuperà solo dello stoccaggio dei dati immessi nella piattaforma dal Cliente ma non avrà accesso diretto ai dati stessi e, pertanto, responsabile del trattamento dei dati personali di terzi soggetti eventualmente immessi nella piattaforma dal Cliente, sarà esclusivamente il Cliente stesso. */}
                </p>
                <p>
                  <b>15. Clausola risolutiva espressa</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  15.1 Impregiudicato ogni altro diritto della concedente,
                  qualsiasi inadempimento, anche di scarsa importanza, da parte
                  del Cliente o alle obbligazioni di cui agli di cui agli artt.
                  5 e 7 del presente contratto ne comporterà l'immediata
                  risoluzione di diritto, ai sensi dell'art. 1456 c. c., fatto
                  salvo il diritto al risarcimento di ogni ulteriore danno.
                  <br /> <br />
                  15.2 II rapporto contrattuale si risolverà di diritto per
                  fatto e colpa del Cliente, se la concedente dichiarerà a mezzo
                  lettera raccomandata A.R. o PEC, che intende avvalersi della
                  presente clausola.
                  <br /> <br />
                  15.3 La dichiarazione di cui ai commi precedenti produrrà
                  effetto dalla data di ricevimento della relativa
                  comunicazione.
                </p>
                <p>
                  <b>16. Clausola di Salvaguardia</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  16.1 La concedente si riserva la facoltà di modificare
                  unilateralmente ognuna delle clausole del presente contratto a
                  suo insindacabile giudizio. Se entro il termine di giorni 30
                  dall'effettuazione di tali modifiche non saranno fatte
                  pervenire osservazioni sulle stesse o non verrà data disdetta
                  del servizio, le modifiche si riterranno accettate.
                </p>
                <p>
                  <b>17. Clausola di rinvio e foro competente</b>
                </p>
                <p style={{ paddingLeft: "2rem" }}>
                  17.1 Le presenti condizioni contrattuali del servizio e il
                  modulo d'ordine disciplinano il rapporto di fornitura del
                  servizio e della attivazione.
                  <br /> <br />
                  17.2 In caso di contrasto tra le disposizioni delle presenti
                  condizioni generali del servizio e le disposizioni specifiche
                  riportate nella richiesta prevarranno le condizioni generali.
                  <br /> <br />
                  17.3 Con l'adesione al servizio il Cliente accetta le clausole
                  del presente condizioni generali del servizio e del modulo
                  d'ordine e dà atto di averne preso visione.
                  <br /> <br />
                  17.4 II presente contratto è regolato dalla Legge italiana.
                  Per tutto quanto non previsto dal presente contratto, si
                  rinvia alle disposizioni contenute nel Codice Civile e nelle
                  leggi speciali.
                  <br /> <br />
                  17.5 Per qualsiasi controversia insorgente tra il produttore e
                  il Cliente in relazione al presente contratto sarà competente
                  il foro di Terni.
                </p>
                <hr />
                <p style={{ paddingLeft: "2rem" }}>
                  L'invio dell'ordine sulla pagina web di Animus Operandi
                  equivale alla dichiarazione espressa ai sensi e per gli
                  effetti degli artt. 1341 e 1342 c. c., di avere preso visione
                  delle clausole contenute negli articoli qui di seguito
                  indicati:
                  <br />
                  art. 3. Conclusione e efficacia del contratto; art 2 -
                  Oggetto; art. 4 - Durata; art. 5 - Prezzi e modalità di
                  pagamento; art. 6 - modifiche delle condizioni del servizio;
                  art. 7 - Obblighi e responsabilità del Cliente; art. 9 -
                  Sospensioni del servizio; art 10 - Restituzione dei dati; art.
                  11 - Esclusione di responsabilità per danni indiretti; art. 12
                  - Forza maggiore; art. 13 - Clausola di riservatezza; art. 14
                  - Privacy; art. 15 - Clausola risolutiva espressa; art. 16 -
                  Clausola di salvaguardia; art 17 - Clausole di rinvio e foro
                  competente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Termini;
import React, {useState, useEffect } from "react";
import "../index.css";
import HeaderHome from "../components/HeaderHome";
import axios from "axios";
function Home() {
  const [error, setError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const[formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    tel: '',
    message: '',
    privacy: false
  });
  
  useEffect(() => {
    if (showSuccessMessage) {
      const timeoutId = setTimeout(() => {
        setShowSuccessMessage(false);

        // Reimposta il form solo dopo che il messaggio è stato visualizzato
        setFormData({
          nome: "",
          cognome: "",
          email: "",
          tel: "",
          message: "",
          privacy: false,
        });
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showSuccessMessage]);

  const handleChangeBool = (e) => {
    const { name, checked } = e.target; // Ottieni il valore checked (booleano)
    setFormData({ ...formData, [name]: checked }); // Aggiorna lo stato con il valore booleano
  };
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/mailami", formData); // Usa Axios per la richiesta POST
      if (response.status === 200) {
        setShowSuccessMessage(true);
      } else {
        setError(response.data.error || "Errore durante l'invio del messaggio");
      }
    } catch (err) {
      console.error("Errore durante l'invio del messaggio:", err);
      setError(
        "Si è verificato un errore durante l'invio del messaggio. Per favore riprova più tardi."
      );
    }
  };

  return (
    <div>
      <HeaderHome />
      <section id="hero" className="hero">
        <div className="container position-relative">
          <div className="row gy-5" data-aos="fade-in">
            <div className="col-lg-6 order-lg-1 order-2 d-flex flex-column align-items-start justify-content-center text-left caption mobile-pt">
              <img
                src="/assets/images/hero.png"
                className="img-fluid rounded-4 mb-4"
                alt=""
              />
            </div>
            <div className="col-lg-6 order-lg-2 order-1 d-flex flex-column align-items-start justify-content-center text-left caption mobile-pt">
              <h2 data-aos="fade-left" data-aos-delay="100">
                <span className="circle" style={{ color: "cyan" }}>
                  Consulenza amministrativa
                </span>
                <br />
                per la Tua Attività.
              </h2>
              <h3 data-aos="zoom-out" data-aos-delay="500">
                Un consulente amministrativo dedicato, senza bisogno di
                assumerlo:{" "}
                <span className="circle" style={{ color: "cyan" }}>
                  in outsourcing
                </span>
              </h3>
              <div className="d-flex" data-aos-delay="1000" data-aos="zoom-out">
                <a href="#contatti" className="btn-get-started">
                  Richiedi una call gratuita
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="top-icon-box position-relative">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="guarantee-services-item">
                  <div className="guarantee-services-icon">
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="guarantee-services-heading">
                    <h3>Accessibile 24/7</h3>
                    <p>
                      Tramite la piattaforma puoi essere operativo in qualunque
                      momento.
                      <br />
                      Ed il tuo consulente prenderà in carico la tua richiesta
                      in tempi brevissimi
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="guarantee-services-item">
                  <div className="guarantee-services-icon">
                    <i className="fa fa-globe"></i>
                  </div>
                  <div className="guarantee-services-heading">
                    <h3>Risposte veloci</h3>
                    <p>
                      La gran parte delle richieste, in orario lavorativo,
                      vengono evase nel giro di un paio d'ore al massimo.
                      <br /> <br />
                      Comunque mai oltre le 24 ore lavorative.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="servizi" className="the-main-service text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-header">
                <h2>
                  Cosa mettiamo <span>a tua Disposizione</span>
                </h2>
                <p>
                  Il nostro servizio, tramite la piattaforma che abbiamo
                  studiato e realizzato ti consente di delegare la gestione ed
                  avere sempre sotto controllo:
                </p>
              </div>
            </div>
          </div>
          <div className="row our-offer-items less-carousel">
            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa fa-pencil"></i>
                <h4>Preventivi e Fatture</h4>
                <p>
                  Richiedi l'emissione di preventivi e fatture, semplicemente
                  caricando un documento.
                  <br />
                  Il tuo consulente provvederà a prepararlo ed inoltrarlo al tuo
                  Cliente!
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa  fa-columns"></i>
                <h4>Prima Nota</h4>
                <p>
                  Lo sappiamo, magari per te non è obbligatoria.
                  <br />
                  Ma è uno strumento fondamentale per la gestione finanziaria
                  della tua impresa.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa fa-line-chart"></i>
                <h4>Andamento finanziario</h4>
                <p>
                  Le tue finanze sempre sotto controllo!
                  <br />
                  Il tuo consulente aggiornerà costantemente la tua
                  disponibilità in cassa
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa fa-group"></i>
                <h4>Clienti & Fornitori</h4>
                <p>
                  Inserisci le anagrafiche dei tuoi Clienti e dei Fornitori.
                  <br />
                  Al resto penserà il tuo consulente.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa fa-handshake-o"></i>
                <h4>Recupero crediti</h4>
                <p>
                  Non hai ricevuto un pagamento? Richiedi il recupero del tuo
                  credito in via bonaria e ci penserà il tuo consulente.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 equal-height">
              <div className="item">
                <i className="fa fa-video-camera"></i>
                <h4>Videocall</h4>
                <p>
                  Vuoi parlare con il tuo consulente?
                  <br />
                  Consulta le disponibilità e fissa una videocall quando ti è
                  più comodo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="chi-siamo" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Chi siamo</h2>
            </div>

            <div className="row gy-4">
              <div className="col-lg-6">
                <img
                  src="/assets/images/whyus.jpg"
                  className="img-fluid rounded-4 mb-4"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="content ps-0" data-aos="fade-right">
                  <p>
                    Animus Operandi è la piattaforma in cloud che rende
                    operativo il Metodo Pantar8, ideato da Catia Lettieri.
                  </p>
                  <p>
                    Animus Operandi permette di delegare la gestione
                    amministrativa della tua azienda, consentendoti di dedicarti{" "}
                    <cite>in toto</cite> al tuo lavoro.
                  </p>
                  <p>La nostra struttura si avvale di:</p>
                  <div
                    className="bar front expert"
                    data-skill="Esperti Contabili"
                  ></div>
                  <div
                    className="bar front expert"
                    data-skill="Business Coach"
                  ></div>
                  <div
                    className="bar front expert"
                    data-skill="Tutor Microcredito"
                  ></div>
                  <div
                    className="bar front expert"
                    data-skill="Consulenti sull'Internazionalizzazione"
                  ></div>
                  <div className="bar front expert" data-skill="Legali"></div>
                  <div
                    className="bar front expert"
                    data-skill="Esperti in Registrazione Marchi"
                  ></div>
                  <div
                    className="bar front expert"
                    data-skill="Esperti di Comunicazione Aziendale"
                  ></div>
                  <div
                    className="bar front expert"
                    data-skill="Sviluppatori Software & Web"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="whyus" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Why Us</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>

            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="content ps-0" data-aos="fade-left">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Sed quia
                      consequuntur magni dolores eos qui ratione
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Neque
                      porro quisquam est, qui dolorem ipsum quia amet
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i>{" "}
                      Consectetur, adipisci velit, sed quia non numquameius
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Rempora
                      incidunt ut labore et dolore magnam aliquam
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Neque
                      porro quisquam est, qui dolorem ipsum quia amet
                    </li>
                  </ul>
                  <div className="d-flex mt-20">
                    <a href="#about" className="fill-button">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content ps-0">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Xonsectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Sed quia
                      consequuntur magni dolores eos qui ratione
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Neque
                      porro quisquam est, qui dolorem ipsum quia amet
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i>{" "}
                      Consectetur, adipisci velit, sed quia non numquameius
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Rempora
                      incidunt ut labore et dolore magnam aliquam
                    </li>
                    <li>
                      <i className="bi bi-arrow-right-circle-fill"></i> Neque
                      porro quisquam est, qui dolorem ipsum quia amet
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="pricing" className="section bg-gray">
          <div className="section-header">
            <h2>
              Un piano per <span>ogni esigenza</span>
            </h2>
            <p>
              Dall'attività individuale appena avviata all'azienda con massimo
              10 dipendenti, abbiamo la soluzione ottimale per te.
            </p>
          </div>
          <div className="container">
            <div className="price-plan-wrapper">
              <div className="row">
                <div className="col-lg-4 col-md-6 ">
                  <div className="pricing-table">
                    <div className="price-header">
                      <h3 className="title">Start-Up</h3>
                      <div className="price">
                        <span className="dollar">€ </span>61
                        <span className="month">/mese*</span>
                      </div>
                    </div>

                    <div className="price-body">
                      <ul>
                        <li>
                          Consulente <b>dedicato</b>
                        </li>
                        <li>
                          <b>Accesso illimitato</b> alla piattaforma
                        </li>
                        <li>
                          <b>3 ore mensili</b> incluse
                        </li>
                        <li>
                          <b>Formazione</b> iniziale
                        </li>
                        <li>
                          <b>Supporto tecnico</b> in tempo reale**
                        </li>
                      </ul>
                    </div>
                    <div className="price-footer">
                      <a className="order-btn" href="#!">
                        Richiedi contatto
                      </a>
                    </div>
                    <div className="price-footer">
                      <span style={{ fontSize: "0.8rem", color: "#fff" }}>
                        * Imposte incluse
                        <br />
                        ** In orari lavorativi
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <div className="pricing-table bg-orange">
                    <div className="price-header">
                      <h3 className="title">Standard</h3>
                      <div className="price">
                        <span className="dollar">€</span>183
                        <span className="month">/mese*</span>
                      </div>
                    </div>

                    <div className="price-body">
                      <ul>
                        <li>
                          Consulente <b>dedicato</b>
                        </li>
                        <li>
                          <b>Accesso illimitato</b> alla piattaforma
                        </li>
                        <li>
                          <b>3 ore mensili</b> incluse
                        </li>
                        <li>
                          <b>Formazione</b> iniziale
                        </li>
                        <li>
                          <b>Supporto tecnico</b> in tempo reale**
                        </li>
                      </ul>
                    </div>
                    <div className="price-footer">
                      <a className="order-btn" href="#!">
                        Richiedi contatto
                      </a>
                    </div>
                    <div className="price-footer">
                      <span style={{ fontSize: "0.8rem", color: "#fff" }}>
                        * Imposte incluse
                        <br />
                        ** In orari lavorativi
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <div className="pricing-table">
                    <div className="price-header" style={{ textAlign: "left" }}>
                      <h3 className="title">Advanced</h3>
                      <div className="price">
                        <span className="dollar">€</span>427
                        <span className="month">/mese*</span>
                      </div>
                    </div>

                    <div className="price-body">
                      <ul>
                        <li>
                          Consulente <b>dedicato</b>
                        </li>
                        <li>
                          <b>Accesso illimitato</b> alla piattaforma
                        </li>
                        <li>
                          <b>25 ore mensili</b> incluse
                        </li>
                        <li>
                          <b>Formazione</b> iniziale
                        </li>
                        <li>
                          <b>Supporto tecnico</b> in tempo reale**
                        </li>
                      </ul>
                    </div>
                    <div className="price-footer">
                      <a className="order-btn" href="#!">
                        Richiedi contatto
                      </a>
                    </div>
                    <div className="price-footer">
                      <span style={{ fontSize: "0.8rem", color: "#fff" }}>
                        * Imposte incluse
                        <br />
                        ** In orari lavorativi
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="call-to-action">
          <div
            className="container text-center call-to-action"
            data-aos="zoom-out"
          >
            <h3>Sei pronto ad iniziare qualcosa di grande insieme?</h3>
            <p>
              {" "}
              Completa il breve questionario per scoprire la soluzione più
              idonea per le tue esigenze.
              <br />
              <br />
              Oppure{" "}
              <a href="#contatti" className="text text-info">
                contattaci
              </a>{" "}
              senza impegno, confrontati con un nostro responsabile e scopri
              come Animus Operandi può supportare e migliorare la tua attività
              imprenditoriale.
            </p>
            <a className="cta-btn" href="/questionario">
              Inizia dal questionario
            </a>
          </div>
        </section>

        <section id="team" className="team">
          <div className="container sections-bg" data-aos="fade-up">
            <div className="section-header">
              <h2>La nostra squadra</h2>
              <p>Perchè ci piace metterci la faccia!</p>
            </div>

            <div className="row gy-4">
              <div
                className="col-xl-4 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member">
                  <img
                    src="/assets/images/team/team-1.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a
                      href="https://www.linkedin.com/in/catialettieri-consulentestrategico-internazionale/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <a
                      href="https://www.catialettieri.it"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-globe"></i>
                    </a>
                  </div>
                  <h4>Catia Lettieri</h4>
                  <span>Founder & C.E.O./C.F.O.</span>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="member">
                  <img
                    src="/assets/images/team/team-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    <a
                      href="https://www.linkedin.com/in/stefanocoggiola/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <a
                      href="https://www.stefanocoggiola.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-globe"></i>
                    </a>
                  </div>
                  <h4>Stefano Coggiola</h4>
                  <span>C.T.O. / C.I.O.</span>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="member">
                  <img
                    src="/assets/images/team/team-3.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="social">
                    &nbsp;
                    {/* <a href="#!" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a> */}
                  </div>
                  <h4>Pamela Di Fronzo</h4>
                  <span>C.O.O.</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-12">
                <div className="content text-center">
                  <h3>
                    F(requently) A(sked) <strong>Q(uestions)</strong>
                  </h3>
                  <p>
                    Le risposte alle domande che ci vengono poste con maggior
                    frequenza
                  </p>
                </div>
              </div>

              <div className="col-lg-12">
                <div
                  className="accordion accordion-flush"
                  id="faqlist"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1"
                      >
                        <span className="num">
                          <i className="bi bi-arrow-right-circle-fill"></i>
                        </span>
                        Perché dovrei aver bisogno di esternalizzare la gestione
                        amministrativa?
                      </button>
                    </h3>
                    <div
                      id="faq-content-1"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Esternalizzare la gestione amministrativa ti permette di
                        <b>concentrarti</b> sulle attività strategiche della tua
                        impresa, senza dover perdere tempo prezioso nelle
                        pratiche burocratiche. Affidarsi a professionisti
                        dedicati ti assicura una gestione accurata e conforme,
                        evitando errori costosi e liberando il tuo tempo per far
                        crescere il tuo business. Inoltre, con{" "}
                        <strong>Animus Operandi</strong>, ottimizzi le risorse
                        senza dover assumere personale interno, ottenendo così
                        un servizio flessibile e altamente specializzato.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2"
                      >
                        <span className="num">
                          <i className="bi bi-arrow-right-circle-fill"></i>
                        </span>
                        Qual è la differenza tra Animus Operandi ed il mio
                        commercialista?
                      </button>
                    </h3>
                    <div
                      id="faq-content-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Animus Operandi non è solo un servizio di contabilità o
                        consulenza fiscale come il tuo commercialista. Offriamo
                        un approccio completo alla gestione delle tue risorse
                        aziendali. Mentre il commercialista si occupa
                        principalmente degli aspetti fiscali e delle
                        dichiarazioni obbligatorie,{" "}
                        <strong>Animus Operandi</strong> ti fornisce un supporto
                        strategico, aiutandoti a ottimizzare il tuo tempo e le
                        tue risorse. Collaboriamo anche con i tuoi consulenti
                        attuali, fornendo soluzioni integrate che migliorano la
                        tua efficienza operativa e ti permettono di concentrarti
                        sulle decisioni chiave per la crescita del tuo business.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-3"
                      >
                        <span className="num">
                          <i className="bi bi-arrow-right-circle-fill"></i>
                        </span>
                        Ho sentito dire che si usufruisce anche di alcune
                        "convenzioni". Di cosa si tratta?
                      </button>
                    </h3>
                    <div
                      id="faq-content-3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Le "<b>convenzioni</b>" di cui parliamo sono accordi
                        esclusivi che abbiamo stretto con partner selezionati.
                        Questi accordi permettono ai nostri clienti di accedere
                        a servizi e prodotti <b>a condizioni vantaggiose</b>,
                        pensati appositamente per le esigenze di chi gestisce
                        una piccola impresa o una startup. Che si tratti di
                        fornitori di software, servizi di consulenza o strumenti
                        operativi, grazie a queste convenzioni potrai ottenere
                        il massimo valore dalle tue risorse.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-4"
                      >
                        <span className="num">
                          <i className="bi bi-arrow-right-circle-fill"></i>
                        </span>
                        Io lavoro da solo... ho appena iniziato... non voglio
                        sostenere costi ulteriori.
                      </button>
                    </h3>
                    <div
                      id="faq-content-4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Capiamo perfettamente le tue preoccupazioni. Lavorare da
                        soli può sembrare una sfida e ogni euro conta. Proprio
                        per questo <strong>Animus Operandi</strong> è pensato
                        per offrirti un supporto su misura. Possiamo aiutarti a
                        ottimizzare il tuo tempo, in modo che tu possa dedicarti
                        al <b>100% alla crescita del tuo progetto</b> senza
                        dover gestire ogni aspetto amministrativo in prima
                        persona. In più, il nostro servizio è modulabile, quindi
                        potrai scegliere esattamente il livello di supporto di
                        cui hai bisogno, senza costi eccessivi e sempre con la
                        massima flessibilità.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients">
          <div className="container" data-aos="zoom-out">
            <div className="row mb-30">
              <div className="col-lg-12">
                <div className="content text-center">
                  <h3>
                    I nostri <strong>Partners</strong>
                  </h3>
                  <p>
                    Collaboriamo con selezionate realtà che mettiamo anche a tua
                    disposizione con servizi convenzionati
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-2 col-sm-3 col-xs-3 mb-20">
                <a
                  href="https://www.catialettieri.it/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/images/convenzioni/catia-lettieri.png"
                    className="img-fluid img-gray"
                    alt="Catia Lettieri"
                  />
                </a>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-3 mb-20">
                <a
                  href="https://www.linkedin.com/company/job-lab/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/images/convenzioni/job-lab.png"
                    className="img-fluid img-gray"
                    alt="Job Lab"
                  />
                </a>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-3 mb-20">
                <a
                  href="https://www.ibow.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/images/convenzioni/idea-business.png"
                    className="img-fluid img-gray"
                    alt="IDEA Business"
                  />
                </a>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-3 mb-20">
                <img
                  src="/assets/images/convenzioni/studio-regine.png"
                  className="img-fluid img-gray"
                  alt="Studio Legale Regine"
                />
              </div>
              <div className="col-md-2 col-sm-3 col-xs-3 mb-20">
                <a
                  href="https://www.ritadellomo.it/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/images/convenzioni/ritadellomo.png"
                    className="img-fluid img-gray"
                    alt="Rita Dell'Omo Eventi"
                  />
                </a>
              </div>

              <div className="col-md-2 col-sm-3 col-xs-3 mb-20"></div>
            </div>
          </div>
        </section>

        {/* <section id="recent-posts" className="recent-posts">
          <div className="container sections-bg" data-aos="fade-up">
            <div className="section-header">
              <h2>Recent Blog Posts</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>
            <div className="row gy-4">
              <div className="col-lg-4">
                <article>
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/blog-1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category">Domain & Hosting</p>
                  <h2 className="title">
                    <a href="#!">
                      How to host website on any hosting provider?
                    </a>
                  </h2>
                  <div className="d-flex align-items-center">
                    <div className="post-meta">
                      <p className="post-author">William Bla</p>
                      <p className="post-date">
                        <time datetime="2022-01-01">Feb 1, 2022</time>
                      </p>
                    </div>
                  </div>
                </article>
              </div>

              <div className="col-lg-4">
                <article>
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/blog-2.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category">Advertisement</p>
                  <h2 className="title">
                    <a href="#!">How to create add on google adwords?</a>
                  </h2>
                  <div className="d-flex align-items-center">
                    <div className="post-meta">
                      <p className="post-author">Jobi Ret</p>
                      <p className="post-date">
                        <time datetime="2022-01-01">Oct 5, 2022</time>
                      </p>
                    </div>
                  </div>
                </article>
              </div>

              <div className="col-lg-4">
                <article>
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/blog-3.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category">Marketing</p>
                  <h2 className="title">
                    <a href="#!">
                      What is digital marketing and why is important?
                    </a>
                  </h2>
                  <div className="d-flex align-items-center">
                    <div className="post-meta">
                      <p className="post-author">Main Dow</p>
                      <p className="post-date">
                        <time datetime="2022-01-01">Dec 22, 2022</time>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section> */}

        <div id="contatti" className="contact-section section">
          <div className="section-header">
            <h2>Contattaci</h2>
            <p>
              Compila il form con i dati richiesti e ti risponderemo al più
              presto
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12" data-aos="fade-left">
                <div className="contact-form-box contact-form contact-form-3">
                  <div className="form-container-box">
                    <form onSubmit={handleSubmit}>
                      <div className="controls">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-input-box">
                              <input
                                type="text"
                                className="form-control"
                                id="nome"
                                name="nome"
                                placeholder="Nome*"
                                autoComplete="given-name"
                                value={formData.nome}
                                required="required"
                                aria-required="true"
                                data-error="Il nome è obbligatorio."
                                onChange={handleChange}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-input-box">
                              <input
                                type="text"
                                className="form-control"
                                id="cognome"
                                name="cognome"
                                placeholder="Cognome*"
                                autoComplete="family-name"
                                value={formData.cognome}
                                required="required"
                                aria-required="true"
                                data-error="Il cognome è obbligatorio."
                                onChange={handleChange}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-input-box">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="E-mail*"
                                autoComplete="email"
                                value={formData.email}
                                required="required"
                                aria-required="true"
                                data-error="Un indirizzo e-mail valido è richiesto."
                                onChange={handleChange}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-input-box">
                              <input
                                type="tel"
                                className="form-control"
                                id="tel"
                                name="tel"
                                placeholder="Telefono"
                                autoComplete="tel"
                                value={formData.tel}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group form-input-box">
                              <textarea
                                className="form-control"
                                id="message"
                                name="message"
                                rows="7"
                                placeholder="La tua richiesta*"
                                value={formData.message}
                                required
                                aria-required="true"
                                data-error="Per favore, lasciaci il tuo messaggio."
                                onChange={handleChange}
                              ></textarea>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group form-input-box">
                              <label htmlFor="privacy">
                                <input
                                  type="checkbox"
                                  id="privacy"
                                  name="privacy"
                                  checked={formData.privacy}
                                  required
                                  aria-required="true"
                                  data-error="Non hai acconsentito al trattamento dei tuoi dati."
                                  onChange={handleChangeBool}
                                />{" "}
                                Ho letto l'
                                <a href="/policies">informativa privacy</a> e
                                acconsento al trattamento dei dati personali*
                              </label>
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              data-text="Invia"
                              className="cta-btn"
                            >
                              Invia
                            </button>
                          </div>
                          {showSuccessMessage && (
                            <>
                              <div
                                className="alert alert-success mt_20"
                                role="alert"
                              >
                                Grazie, il tuo messaggio è stato inviato. Ti
                                risponderemo nel minor tempo possibile.
                              </div>
                            </>
                          )}
                          {error && (
                            <div
                              className="alert alert-danger mt_20"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12" data-aos="fade-right">
                <div className="contact-information-box-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="single-contact-info-box">
                        <div className="contact-info">
                          <h6>Sede legale:</h6>
                          <p>Via Giuseppe Mazzini, 38</p>
                          <p>05021 Acquasparta (TR - Italy)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-contact-info-box">
                        <div className="contact-info">
                          <h6>Telefono:</h6>
                          {/* <p>+39 (0)744 434 332</p> */}
                          <p>+39 351 377 2245</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-contact-info-box">
                        <div className="contact-info">
                          <h6>E-mail:</h6>
                          <p>info@animusoperandi.it</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import HomePage from "./pages/Home";
import Questionario from "./pages/Questionario";
import Ossidiana from "./pages/Ossidiana";
import Smeraldo from "./pages/Smeraldo";
import Perla from "./pages/Perla";
import Ambra from "./pages/Ambra";
import Rubino from "./pages/Rubino";
import CaudaPavonis from "./pages/CaudaPavonis";
import Termini from "./pages/TerminiECondizioni";
import Policies from "./pages/Policies";
import Cookie from "./pages/Cookie";
import "./index.css";

function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/questionario" element={<Questionario />} />
          <Route path="/ossidiana" element={<Ossidiana />} />
          <Route path="/smeraldo" element={<Smeraldo />} />
          <Route path="/perla" element={<Perla />} />
          <Route path="/ambra" element={<Ambra />} />
          <Route path="/rubino" element={<Rubino />} />
          <Route path="/cauda-pavonis" element={<CaudaPavonis />} />
          <Route path="/termini-e-condizioni" element={<Termini />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/cookie" element={<Cookie />} />
          {/* ... altre rotte per le pagine */}
        </Routes>
        <Footer />
      </BrowserRouter>
    );
}

export default App;
